<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters class="fill-height">
      <v-col cols="12" class="flex overflow-y-auto white d-flex justify-center">
        <router-view />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'TripView',
  data() {
    return {
      inactivityTimer: null,
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        if (!from || to.params.id !== from.params.id) {
          this.$store.dispatch('getTouristTripInfo', this.$route.params.id)
          this.setupInactivityRedirect()
        }
      },
    },
  },
  methods: {
    setupInactivityRedirect() {
      if (this.$route.query.origin === 'search') {
        if (this.inactivityTimer) {
          clearTimeout(this.inactivityTimer)
        }

        const resetTimer = this.resetInactivityTimer.bind(this)
        document.addEventListener('mousemove', resetTimer)
        document.addEventListener('keypress', resetTimer)
        document.addEventListener('click', resetTimer)
        document.addEventListener('scroll', resetTimer)

        this.resetInactivityTimer()
      }
    },
    resetInactivityTimer() {
      if (this.inactivityTimer) {
        clearTimeout(this.inactivityTimer)
      }

      this.inactivityTimer = setTimeout(() => {
        // Redirect to search page after 3 minutes
        this.$router.push('/search')
      }, 3 * 60 * 1000) // 3 minutes in milliseconds
    },
  },
  beforeDestroy() {
    // Cleanup
    if (this.inactivityTimer) {
      clearTimeout(this.inactivityTimer)
    }

    // Remove event listeners
    document.removeEventListener('mousemove', this.resetInactivityTimer)
    document.removeEventListener('keypress', this.resetInactivityTimer)
    document.removeEventListener('click', this.resetInactivityTimer)
    document.removeEventListener('scroll', this.resetInactivityTimer)
  },
}
</script>

<style scoped></style>
