<template>
  <div class="d-flex flex-column align-center">
    <form id="payment-form" class="w-100">
      <label>Card Holder Name</label>
      <div class="mb-4">
        <input
          id="cardholder-name"
          class="field w-100"
          placeholder="John Doe"
        />
      </div>

      <label>Email</label>
      <div class="mb-4">
        <input
          id="email"
          class="field w-100"
          placeholder="john.doe@example.com"
        />
      </div>

      <label>Phone</label>
      <div class="mb-4">
        <phone-input
          :value.sync="phone"
          :error.sync="showPhoneError"
          :initial-phone="initialPhone"
        />
      </div>

      <label>Card Number</label>
      <div id="card-number" class="field mb-4">
        <div class="blox-input"></div>
      </div>

      <div class="field-set d-flex gap-3">
        <div class="flex-1">
          <label>Expiration Date</label>
          <div id="exp-date" class="field">
            <div class="input"></div>
          </div>
        </div>

        <div class="flex-1">
          <label>CVV</label>
          <div id="cvv" class="field w-100">
            <div class="input"></div>
          </div>
        </div>
      </div>
      <div v-if="formError" class="error--text text-caption mt-1">
        {{ formError }}
      </div>

      <v-btn
        class="mt-8"
        type="submit"
        block
        depressed
        color="success"
        :loading="processing"
        >Confirm & Pay</v-btn
      >
    </form>
  </div>
</template>

<script>
import { loadScript } from '@/utils/script-loader'
import PhoneInput from '@/components/common/phone-input.vue'
import axios from 'axios'

export default {
  name: 'BridgerBlox',
  components: {
    PhoneInput,
  },

  props: {
    sessionToken: {
      type: String,
      required: true,
    },
    intentId: {
      type: String,
      required: true,
    },
    initialName: {
      type: String,
      default: '',
    },
    initialEmail: {
      type: String,
      default: '',
    },
    initialPhone: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      cardHolderName: '',
      processing: false,
      errorMessage: '',
      showRetry: false,
      hidePayment: false,
      bloxController: null,
      isValid: false,
      phone: '',
      showPhoneError: false,
      formError: null,
    }
  },

  async mounted() {
    await this.initializeBlox()
    document.getElementById('cardholder-name').value = this.initialName
    document.getElementById('email').value = this.initialEmail
    document
      .getElementById('payment-form')
      .addEventListener('submit', async event => {
        event.preventDefault()
        this.formError = null

        if (this.bloxController.isAllValid === false) {
          this.bloxController.validateAll()
          return
        }
        const cardHolderName = document.getElementById('cardholder-name').value
        const email = document.getElementById('email').value
        if (!cardHolderName)
          return (this.formError = 'Please enter a Card Holder Name')
        if (!email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email))
          return (this.formError = 'Please enter a valid email address')
        if (!this.phone.isValid)
          return (this.formError = 'Phone enter a valid phone number')

        try {
          this.processing = true
          const data = await this.bloxController.createPaymentCardToken({
            cardHolderName: document.getElementById('cardholder-name').value, // This field is mandatory
            singleUse: true,
          })

          axios
            .post('/api/bridgerpay/purchase', {
              token: data.token,
              encrypted_cvv: data.encryptedCvv,
              payment_intent_id: this.intentId,
              full_name: cardHolderName,
              email,
              phone: this.phone.formattedNumber,
            })
            .then(() => {
              this.$emit('payment-success', {
                user: { email: this.email },
              })
            })
        } catch (error) {
          console.error(error)
        }
      })
  },

  beforeDestroy() {
    if (this.bloxController) {
      this.bloxController.clearAll()
    }
  },

  methods: {
    async initializeBlox() {
      try {
        await loadScript('https://checkout.bridgerpay.com/sdk')
        this.bloxController = new window.BP.BloxController({
          token: this.sessionToken,
          style: {
            base: {
              fontFamily: 'Montserrat, sans-serif',
              fontSize: '14px',
            },
            invalid: {
              color: '#ff6666',
            },
          },
        })

        const cardNumber = this.bloxController.createBlox('cardNumber', {
          placeholder: this.$t('Card Number'),
        })

        const cardExpiryDate = this.bloxController.createBlox(
          'cardExpiryDate',
          { placeholder: 'MM/YY' }
        )

        const cardCvv = this.bloxController.createBlox('cardCvv', {
          placeholder: this.$t('CVV'),
        })
        await Promise.all([
          cardNumber.mount('#card-number'),
          cardExpiryDate.mount('#exp-date'),
          cardCvv.mount('#cvv'),
        ])

        this.setupValidationListeners(cardNumber, cardExpiryDate, cardCvv)
      } catch (error) {
        console.error('Failed to initialize Blox:', error)
      }
    },

    setupValidationListeners(cardNumber, cardExpiryDate, cardCvv) {
      const validateForm = ({ error }) => {
        if (error) this.formError = error
      }

      cardNumber.on('validate', validateForm)
      cardExpiryDate.on('validate', validateForm)
      cardCvv.on('validate', validateForm)
    },
  },
}
</script>

<style scoped>
.field {
  border-color: var(--v-secondary-base);
  border-width: 1px;
  border-radius: 4px;
  border-style: solid;
  padding: 10px;
  font-size: 14px;
}
.w-100 {
  width: 100%;
}
.flex-1 {
  flex: 1;
}
.gap-3 {
  grid-gap: 7px;
}
</style>
