<template>
  <NotFound v-if="paymentNotFound" />
  <v-container v-else>
    <div class="d-flex flex flex-column map-wrapper">
      <v-dialog
        v-model="dialog"
        :fullscreen="isMobile"
        persistent
        max-width="450px"
      >
        <v-card
          class="d-flex pa-7 justify-center align-center"
          style="position: relative"
          height="450"
        >
          <v-col v-if="status === 'processing'" cols="9">
            <DvrLoader />
          </v-col>
          <div
            v-else
            class="d-flex flex-column justify-center align-center flex"
          >
            <img src="@/assets/images/payment-success.png" width="200" alt="" />
            <div class="text-h6 my-3">{{ $t('PaymentSuccessful') }}</div>
            <div class="text-caption my-3 px-7 px-sm-3">
              {{ $t('BookingConfirmationMessage') }}
            </div>
            <v-btn class="mt-3 px-10" color="primary" @click="goToReservation">
              {{ $t('OK') }}
            </v-btn>
          </div>
        </v-card>
      </v-dialog>
      <div v-if="isDeposit" class="text-h4 py-4 px-0">
        {{ $t('BookingDeposit') }}
      </div>
      <div v-else class="text-h4 py-4 px-0">{{ $t('Checkout') }}</div>
      <div v-if="isDeposit" pa-3 class="text-caption">
        Hello <b>{{ reservation.guest.full_name }}</b
        >, Thank you for choosing {{ listingName }} for your vacation rental
        stay! <br />
        We are notifying you that a security deposit authorization hold of
        <b>{{ toCurrency(payment.amount / 100) }}</b>
        will be placed on the credit card used for booking on
        {{
          formatDate(
            reservation.check_in,
            'YY/MM/DD',
            reservation.listing.timezone
          )
        }}
        -
        {{
          formatDate(
            reservation.check_out,
            'YY/MM/DD',
            reservation.listing.timezone
          )
        }}. This hold will be released up to 2 days after checkout pending no
        damages were incurred at property.
      </div>
      <div v-else class="text-caption pb-8">
        <span
          v-html="
            $t('WelcomeMessage', {
              guestName: reservation.guest.full_name,
              listingName: listingName,
            })
          "
        >
        </span>
        <span
          v-if="!$vuetify.breakpoint.xs"
          v-html="$t('PostBookingMessage')"
          class="ms-1"
        >
        </span>
      </div>
      <v-row no-gutters>
        <v-col cols="12" md="6">
          <v-card class="mx-auto" max-width="800" elevation="0">
            <v-card-text class="px-0">
              <spreedly
                v-if="spreedlyEnvironmentKey"
                :environment-key="spreedlyEnvironmentKey"
                :payment-intent-id="payment.payment_intent_id"
                button-text="Confirm & pay"
                @payment-success="paymentSuccess"
                @payment-processing="paymentProcessing"
                @payment-failed="paymentFailed"
                :reservation="reservation"
              />
              <bridger-blox
                v-else-if="bridgerpayCredentials"
                :session-token="bridgerpayCredentials.cashier_token"
                :intent-id="payment.payment_intent_id"
                :initial-name="reservation.guest.full_name"
                :initial-email="reservation.guest.primary_email"
                :initial-phone="reservation.guest.primary_phone"
                @payment-processing="paymentProcessing"
                @payment-success="paymentSuccess"
                @payment-failed="paymentFailed"
              />
              <StripeElement
                v-else-if="publicKey"
                :public-key="publicKey"
                :client-secret="clientSecret"
                :reservation="reservation"
                button-text="Confirm & pay"
                @payment-success="paymentSuccess"
                @payment-processing="paymentProcessing"
                @payment-failed="paymentFailed"
              >
                <ReservationCheckoutSummary
                  v-if="isMobileOrIPad"
                  :reservation="reservation"
                  :payment="payment"
                />
              </StripeElement>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="0" md="1" />
        <v-col v-if="!isMobileOrIPad" cols="12" md="5">
          <div class="d-flex justify-center">
            <v-col cols="12" xl="8" class="ml-md-8 pa-0">
              <ReservationCheckoutSummary
                :reservation="reservation"
                :payment="payment"
              />
            </v-col>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import StripeElement from 'components/stripe/StripeCustomElement'
import deviceMixin from 'components/mixins/deviceMixin'
import commonFunctions from 'components/mixins/common-functions'
import DvrLoader from '@/assets/icons/dvr-loader'
import axios from 'axios'
import Spreedly from 'components/spreedly'
import BridgerBlox from 'components/bridgerpay/bridger-blox'
import ReservationCheckoutSummary from '@/components/reservations/reservation-checkout-summary'
import NotFound from '@/components/common/not-found'
import { HOTJAR_EVENTS } from '@/lib/analytics/hotjar'
import { mapActions } from 'vuex'
import get from 'lodash/fp/get'

export default {
  name: 'StandaloneCheckout',
  components: {
    NotFound,
    ReservationCheckoutSummary,
    DvrLoader,
    StripeElement,
    Spreedly,
    BridgerBlox,
  },
  mixins: [deviceMixin, commonFunctions],
  data() {
    return {
      dialog: false,
      status: null,
      mainReservationCode: null,
      clientSecret: '',
      publicKey: '',
      spreedlyEnvironmentKey: '',
      payment: {},
      reservation: { listing: {}, guest: {}, invoice_items: [] },
      paymentNotFound: false,
      bridgerpayCredentials: null,
    }
  },
  computed: {
    paymentIntentId() {
      return this.$route.params.intent_id
    },
    isDeposit() {
      return this.payment.capture_method === 'manual'
    },
    listingName() {
      return get('listing.resort_name', this.reservation)
    },
  },
  async mounted() {
    try {
      const {
        data: {
          payment,
          reservation,
          stripe_public_key,
          spreedly_environment_key,
          bridgerpay_credentials,
        },
      } = await axios.get(`/api/checkout-info/${this.paymentIntentId}`)

      if (
        payment.status === 'succeeded' ||
        payment.status === 'requires_capture' ||
        (this.reservation.balance_due === 0 &&
          payment.metadata.type === 'accommodation')
      ) {
        this.status = 'success'
        this.dialog = true
        return
      }
      this.clientSecret = payment.client_secret
      this.publicKey = stripe_public_key
      this.spreedlyEnvironmentKey = spreedly_environment_key
      this.bridgerpayCredentials = bridgerpay_credentials

      this.payment = payment
      this.reservation = reservation
    } catch (err) {
      this.$store.commit('updateLoading', false)
      if (err.response.status === 404) {
        this.paymentNotFound = true
      }
    }
  },
  methods: {
    ...mapActions(['logAction']),
    paymentSuccess({ user }) {
      this.logAction({
        eventType: HOTJAR_EVENTS.PAYMENT_SUCCESSFUL,
        storeItemId: this.checkoutItem && this.checkoutItem.store_item_id,
      })
      this.dialog = true
      setTimeout(() => {
        this.status = 'success'
        this.user = user
      }, 3000)
    },
    paymentProcessing() {
      this.logAction({
        eventType: HOTJAR_EVENTS.PAYMENT_ATTEMPT,
        storeItemId: this.checkoutItem && this.checkoutItem.store_item_id,
      })
      this.status = 'processing'
      this.dialog = true
    },
    paymentFailed() {
      this.dialog = false
      this.status = 'failed'
    },
    goToReservation() {
      this.$router.push({
        name: 'reservation-info',
        params: {
          id: this.reservation.confirmation_code,
        },
      })
    },
  },
}
</script>

<style scoped></style>
