<template>
  <v-container class="pa-0 login-wrapper d-flex" fluid>
    <v-row class="mx-0 fill-height">
      <v-col
        v-if="!isMobile"
        cols="6"
        class="pa-0 fill-height"
        style="background: #dfe0d8"
      >
        <div style="height: 100vh" class="d-flex align-center">
          <v-img src="@/assets/images/reception.webp" height="500px" contain />
        </div>
      </v-col>
      <v-col cols="12" sm="6" class="d-flex align-center">
        <v-row justify="center">
          <v-col cols="11" lg="7" xl="6" class="p-relative" elevation="0">
            <div class="text-center">
              <h2
                class="text-h4 font-weight-bold text-center text-capitalize mb-6"
              >
                Reservation Search
              </h2>
              <v-form @submit.prevent="goToReservation">
                <v-text-field
                  v-model="confirmationCode"
                  name="confirmationCode"
                  label="Confirmation Code"
                  type="text"
                  outlined
                  dense
                  autocomplete="off"
                  @input="error = ''"
                />
                <v-text-field
                  v-model="lastName"
                  name="lastName"
                  label="Last Name"
                  type="text"
                  outlined
                  dense
                  autocomplete="off"
                  @input="error = ''"
                />
                <v-btn
                  type="submit"
                  color="primary"
                  class="mt-8"
                  elevation="0"
                  :loading="loading"
                  block
                  x-large
                >
                  <span class="text-capitalize white--text">
                    Go to Reservation</span
                  >
                </v-btn>
                <div v-if="error" class="text-caption error--text mt-2">
                  {{ error }}
                </div>
              </v-form>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import deviceMixin from 'components/mixins/deviceMixin'

export default {
  name: 'ConfirmationCodePage',
  mixins: [deviceMixin],
  data() {
    return {
      confirmationCode: '',
      lastName: '',
      error: '',
      loading: false,
    }
  },
  methods: {
    async goToReservation() {
      const invalidCode =
        'The confirmation code or last name does not match our records. Please verify your information and try again.'
      if (!this.confirmationCode) {
        return (this.error = 'Please enter a confirmation code')
      }
      if (!this.lastName) {
        return (this.error =
          'Please enter your last name as it appears on your reservation')
      }
      this.loading = true
      const isValid = await this.$store
        .dispatch('validateUser', {
          id: this.confirmationCode,
          last_name: this.lastName,
        })
        .catch(() => {
          this.error = invalidCode
          this.loading = false
        })
      if (!isValid) return (this.error = invalidCode)
      this.loading = false
      const url = `${window.location.origin}/tourist/trips/${this.confirmationCode}/info?origin=search`
      window.open(url, '_blank')
    },
  },
}
</script>
