import Vue from 'vue'
import VueRouter from 'vue-router'
import TouristsLayoutPortal from 'components/portal-layout.vue'
import TouristPortal from 'components/portal.vue'
import TripView from 'components/trips/trip-view.vue'
import TripPreCheckIn from 'components/trips/trip-pre-check-in'
import TouristTrips from 'components/trips/trips-page.vue'
import StandaloneCheckout from 'components/reservations/standalone-checkout'
import ReservationCheckout from 'components/reservations/reservation-checkout'
import StoreCheckout from 'components/store/store-checkout'
import PurchaseRequestSuccess from 'components/store/purchase-request-success'
import StorePage from 'components/store/store-page'
import PurchaseHistory from 'components/store/purchase-history'
import TouristLogin from 'components/users/login-page.vue'
import TouristSignup from 'components/users/signup-page.vue'
import ReservationInfo from 'components/reservations/reservation-info.vue'
import TouristStoreView from 'components/store/tourist-store-view'
import TouristStoreLayout from 'components/store/store-layout'
import ReviewForm from 'components/review/review-form'
import ListingTaskTracker from 'components/reservations/listing-task-tracker.vue'
import ConfirmationCodePage from 'components/hotel/confirmation-code-page.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/checkout/:intent_id',
    component: StandaloneCheckout,
  },
  {
    path: '/',
    redirect: '/tourist',
  },
  {
    name: 'standalone-store-checkout',
    path: '/store-checkout/:id',
    component: StoreCheckout,
  },
  {
    name: 'purchase-request-success',
    path: '/purchase-request-success',
    component: PurchaseRequestSuccess,
  },
  {
    path: '/tourist',
    name: 'tourist-portal',
    component: TouristsLayoutPortal,
    redirect: '/tourist',
    children: [
      {
        path: '/',
        name: 'tourist-home-page',
        component: TouristPortal,
        children: [
          {
            name: 'tourist-trips',
            path: '(trips)?',
            component: TouristTrips,
          },
          {
            name: 'store-checkout',
            path: 'store/checkout/:id',
            component: StoreCheckout,
          },
          {
            name: 'store-main',
            path: 'store',
            component: TouristStoreLayout,
            children: [
              {
                name: 'store-tabs',
                path: '',
                component: StorePage,
                children: [
                  {
                    name: 'purchase-history',
                    path: 'history',
                    component: PurchaseHistory,
                  },
                ],
              },
              {
                path: 'reservation/:id',
                name: 'tourist-store',
                component: TouristStoreView,
              },
            ],
          },
          {
            path: 'trips/:id',
            component: TripView,
            children: [
              {
                name: 'pre-check-in',
                path: '',
                component: TripPreCheckIn,
              },
              {
                path: 'info',
                name: 'reservation-info',
                component: ReservationInfo,
              },
              {
                path: 'checkout/:intent_id',
                name: 'reservation-checkout',
                component: ReservationCheckout,
              },
            ],
          },
        ],
      },
      {
        path: 'login',
        name: 'login',
        component: TouristLogin,
      },
      {
        path: 'signup',
        component: TouristSignup,
      },
      {
        path: '*',
        redirect: '/tourist',
      },
    ],
  },
  {
    path: '/tourist-lt/:id',
    component: ListingTaskTracker,
  },
  {
    path: '/reservations/:id/survey',
    name: 'survey',
    component: ReviewForm,
  },
  {
    path: '/search',
    name: 'hotel-lookup',
    component: ConfirmationCodePage,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
