<template>
  <v-card>
    <v-data-table
      :headers="headers"
      :items="purchaseHistory"
      class="elevation-1"
    >
      <template v-slot:item="{ item }">
        <tr>
          <td>{{ item.store_item.name }}</td>
          <td>
            {{ item.description }}
          </td>
          <td>
            <div>{{ item.reservation.listing.nickname }}</div>
          </td>
          <td>
            {{
              formatDate(
                item.reservation.check_in,
                'YYYY MMM Do',
                item.reservation.listing.timezone
              )
            }}
          </td>
          <td>
            {{
              toCurrency(item.total_amount, {
                currency: item?.reservation?.listing?.get_currency,
              })
            }}
          </td>
          <td>
            {{ item.status }}
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import CommonFunctions from 'components/mixins/common-functions.js'

export default {
  mixins: [CommonFunctions],
  data() {
    return {
      headers: [
        { text: 'Item', sortable: false },
        { text: 'Reservation', sortable: false },
        { text: 'Description', sortable: false },
        { text: 'Check-in', sortable: false },
        { text: 'Price', sortable: false },
        { text: 'Status', sortable: false },
      ],
    }
  },
  computed: {
    purchaseHistory() {
      return this.$store.getters.purchaseHistory
    },
  },

  mounted() {
    this.$store.dispatch('getPurchaseHistory')
  },
}
</script>
