<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="isMobile"
    max-width="500px"
    class="rounded-xl"
    @click:outside="itemClosed"
  >
    <template #activator="{ on }">
      <div class="text-caption secondary--text">
        {{ itemDescription }}
      </div>
      <v-btn
        color="primary"
        elevation="0"
        :disabled="isDisabled"
        block
        class="rounded text-subtitle-2 darken-1 font-weight-bold white--text"
        v-on="on"
        @click="itemClicked"
      >
        {{ $t(buttonText) }}
      </v-btn>
    </template>
    <v-card>
      <v-avatar tile size="80" class="rounded ml-6 mt-16">
        <img :src="formatImageUrl(storeItem.image)" alt="" />
      </v-avatar>
      <v-btn
        style="z-index: 10"
        icon
        light
        :fixed="isMobile"
        :absolute="!isMobile"
        top
        left
        @click="dialog = false"
      >
        <v-icon color="grey darken-2"> fas fa-arrow-left </v-icon>
      </v-btn>
      <v-card-title
        class="text-subtitle-1 font-weight-bold black--text d-flex text-h5 justify-space-between"
      >
        <div class="text-sm-h6">{{ storeItem.name }}</div>
        <div class="indigo--text text--darken-4">
          {{ priceUnits }}
        </div>
      </v-card-title>
      <v-divider />
      <v-card-text class="py-4 black--text card-container">
        <v-form v-if="hasSpecifications" ref="form" class="pt-3">
          <div v-for="option in options" :key="option.key">
            <div v-if="option.units === 'datetime' && option.type === 'select'">
              <div class="text-subtitle-1 font-weight-medium">
                {{ parseLabel(option) }}
              </div>
              <v-select
                v-model="fields[option.key]"
                :rules="[required]"
                outlined
                color="black"
                :items="option.value"
              >
                <template #selection="{ item }">
                  <span>{{ formatDate(item) }}</span>
                </template>
                <template #item="{ item }">
                  <span>{{ formatDate(item) }}</span>
                </template>
              </v-select>
            </div>
          </div>
        </v-form>
        <div class="text-subtitle-1">{{ $t('Description') }}</div>
        <div class="text-caption">
          {{ storeItem.description }}
        </div>
        <div class="py-3">
          <div
            v-if="storeItem.bullets && storeItem.bullets.length"
            class="text-subtitle-1"
          >
            {{ $t("What's included") }}?
          </div>
          <div v-if="storeItem.bullets" class="text-caption d-flex">
            <v-row no-gutters class="flex-wrap">
              <v-col v-for="i in storeItem.bullets" :key="i" cols="12" sm="6">
                <li class="text-capitalize">
                  {{ i }}
                </li>
              </v-col>
            </v-row>
          </div>
        </div>
        <div v-if="storeItem.disclaimer" class="pb-3">
          <div class="text-subtitle-1">{{ $t('Disclaimer') }}</div>
          <div class="text-caption">
            {{ storeItem.disclaimer }}
          </div>
        </div>
        <div v-if="storeItem.max_quantity > 1" class="pb-1">
          <div class="text-subtitle-1">{{ $t('Quantity') }}</div>
          <div class="d-flex align-center mt-2">
            <v-btn
              icon
              x-small
              :disabled="quantity <= 1"
              @click="updateQuantity(-1)"
            >
              <v-icon>mdi-minus</v-icon>
            </v-btn>
            <div class="mx-4 text-subtitle-2">{{ quantity }}</div>
            <v-btn
              icon
              x-small
              :disabled="quantity >= storeItem.max_quantity"
              @click="updateQuantity(1)"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
        </div>
        <div v-if="hasMedia" class="align-center pointer">
          <gallery :thumbnails="true" :cols="4" :images="storeItem.media" />
        </div>
      </v-card-text>
      <v-divider />
      <v-card-actions class="flex-wrap pa-5">
        <div
          v-if="currentTotal"
          class="d-flex py-2 flex text-subtitle-1 font-weight-medium justify-space-between"
        >
          <div>{{ $t('Total') }}</div>
          <div>{{ toCurrency(currentTotal, { currency }) }}</div>
        </div>
        <v-btn
          v-if="inquiryOnly"
          :loading="loading"
          color="primary"
          elevation="0"
          class="rounded text-subtitle-2 darken-1 font-weight-bold white--text"
          block
          :disabled="status === 'requested'"
          @click="placeRequest"
          ><span v-if="status === 'requested'">
            <v-icon small class="mr-2"> fas fa-check </v-icon>
            {{ $t('Requested') }}</span
          >
          <span v-else>{{ $t('Request') }}</span>
        </v-btn>
        <v-btn
          v-else
          :loading="loading"
          color="primary"
          elevation="0"
          :disabled="isDisabled || !isDisclaimerAccepted"
          block
          class="rounded text-subtitle-2 darken-1 font-weight-bold white--text"
          @click="placeOrder"
        >
          {{
            $t(
              hasAbility('create-store-purchase')
                ? 'Create Payment Request'
                : 'Reserve'
            )
          }}
        </v-btn>
        <v-dialog v-model="requestSentDialog" max-width="600">
          <v-card class="pa-3">
            <v-card-title
              class="text-h5 font-weight-bold d-flex justify-center"
            >
              {{ storeItem.name }} Requested
            </v-card-title>
            <v-card-text class="my-4">
              <div class="text-subtitle-1 text-center">
                Our team will reach out to your shortly with more details about
                your request.
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn color="primary" text @click="requestSentDialog = false">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-checkbox
          v-if="storeItem.enforce_disclaimer"
          v-model="acceptDisclaimer"
          class="small-checkbox mt-2"
          hide-details
          color="primary"
        >
          <template #label>
            <div class="text-body-1 primary--text font-weight-medium">
              I have read and agree to the disclaimer
            </div>
          </template>
        </v-checkbox>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import isEmpty from 'lodash/fp/isEmpty'
import { DISABLED_STORE_ITEM_STATUSES } from '@/consts'
import { HOTJAR_EVENTS } from '@/lib/analytics/hotjar'
import formRules from 'components/mixins/form-rules-mixin'
import { mapActions } from 'vuex'
import deviceMixin from 'components/mixins/deviceMixin'
import moment from 'moment'
import CommonFunctions from 'components/mixins/common-functions'
import Gallery from 'components/common/gallery.vue'

export default {
  name: 'StoreItemDialog',
  components: {
    Gallery,
  },
  mixins: [formRules, deviceMixin, CommonFunctions],
  props: ['storeItem', 'price', 'options', 'status', 'currency'],
  data() {
    const fields = this.options.reduce((acc, op) => {
      acc[op.key] = null
      return acc
    }, {})
    return {
      dialog: false,
      requestSentDialog: false,
      acceptDisclaimer: false,
      loading: false,
      fields,
      quantity: 1,
    }
  },
  computed: {
    priceUnits() {
      if (this.price.per_hour) {
        return `$${this.price.per_hour} / hour`
      }
      if (this.price.per_day) {
        return `$${this.price.per_day} / day`
      }
      return ''
    },
    hasSpecifications() {
      return !isEmpty(this.options)
    },
    hasMedia() {
      return !isEmpty(this.storeItem.media)
    },
    isDisclaimerAccepted() {
      return !this.storeItem.enforce_disclaimer || this.acceptDisclaimer
    },
    isDisabled() {
      return DISABLED_STORE_ITEM_STATUSES.includes(this.status)
    },

    inquiryOnly() {
      return this.storeItem.inquiry_only
    },
    buttonText() {
      if (this.isDisabled) {
        return this.status.replace('_', ' ')
      }
      return 'View'
    },
    itemDescription() {
      if (this.status === 'future') {
        return `Will be available ${moment
          .utc(this.storeItem.available_from)
          .format('MMM Do')}`
      }
      return ''
    },
    currentTotal() {
      return this.price.total * this.quantity
    },
  },
  mounted() {
    const { query } = this.$route
    if (query && +query.selectedItem === this.storeItem.id) {
      this.dialog = true
    }
  },
  methods: {
    ...mapActions(['logAction']),
    formatDate(item) {
      const startDate = moment.utc(item).format('ddd, MMM Do, HH:mm')
      if (this.storeItem.duration) {
        return `${startDate} - ${moment
          .utc(item)
          .add(this.storeItem.duration, 'hour')
          .format('HH:mm')}`
      }
      return startDate
    },
    parseLabel({ label, units }) {
      if (units === 'datetime') {
        return 'Select date & time'
      }
      return label.replace('_', ' ')
    },
    async placeRequest() {
      this.loading = true
      await this.$store.dispatch('createAuthorizationRequest', {
        reservationId: this.$route.params.id,
        item_id: this.storeItem.id,
        options: this.fields,
        count: this.quantity,
      })
      this.$store.dispatch('getReservationStoreItems', this.$route.params.id)
      this.loading = false
      this.dialog = false
      this.requestSentDialog = true
    },
    async placeOrder() {
      this.logAction({
        eventType: HOTJAR_EVENTS.COMPOSE_ORDER_ATTEMPT,
        storeItemId: this.storeItem.id,
      })
      if (
        !this.hasSpecifications ||
        (this.hasSpecifications && this.$refs.form.validate())
      ) {
        this.loading = true
        const purchase = await this.$store.dispatch('placeOrder', {
          reservationId: this.$route.params.id,
          itemId: this.storeItem.id,
          options: this.fields,
          count: this.quantity,
        })
        this.logAction({
          eventType: HOTJAR_EVENTS.COMPOSE_ORDER_SUCCESSFUL,
          storeItemId: this.storeItem.id,
        })
        this.$router.push({
          name: this.hasAbility('create-store-purchase')
            ? 'purchase-request-success'
            : 'store-checkout',
          query: { ...this.$route.query },
          params: { id: purchase.payment_intent.payment_intent_id },
        })
      }
    },
    itemClicked() {
      this.$router.push({
        query: { ...this.$route.query, selectedItem: this.storeItem.id },
      })
      this.logAction({
        eventType: HOTJAR_EVENTS.VIEW_ITEM,
        storeItemId: this.storeItem.id,
      })
    },
    itemClosed() {
      this.$router.push({ query: null })
    },
    updateQuantity(change) {
      const newQuantity = this.quantity + change
      if (newQuantity >= 1 && newQuantity <= this.storeItem.max_quantity) {
        this.quantity = newQuantity
      }
    },
  },
}
</script>

<style scoped>
.card-container {
  overflow: scroll;
}
.small-checkbox {
  font-size: 12px;
  transform: scale(0.8);
  margin-left: -35px;
  font-weight: 500;
  color: var(--v-info-base);
}
</style>
